import {ClassicEditor as ClassicEditorBase} from '@ckeditor/ckeditor5-editor-classic';
import {Alignment} from '@ckeditor/ckeditor5-alignment';
// import Autoformat from '@ckeditor/ckeditor5-autoformat';
import {BlockQuote} from '@ckeditor/ckeditor5-block-quote';
import {Bold, Italic, Underline, Strikethrough} from '@ckeditor/ckeditor5-basic-styles';
import {Essentials} from '@ckeditor/ckeditor5-essentials';
import {FontBackgroundColor, FontColor, FontFamily, FontSize} from '@ckeditor/ckeditor5-font';
import {Heading} from '@ckeditor/ckeditor5-heading';
import {HorizontalLine} from '@ckeditor/ckeditor5-horizontal-line';
import {HtmlEmbed} from "@ckeditor/ckeditor5-html-embed";
import {Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload, ImageTextAlternative} from '@ckeditor/ckeditor5-image';
import {Indent} from '@ckeditor/ckeditor5-indent';
import {MediaEmbed, MediaEmbedToolbar} from "@ckeditor/ckeditor5-media-embed";
import {Link, LinkImage} from '@ckeditor/ckeditor5-link';
import {List, ListProperties} from '@ckeditor/ckeditor5-list';
import {Paragraph} from '@ckeditor/ckeditor5-paragraph';
import {PasteFromOffice} from '@ckeditor/ckeditor5-paste-from-office';
// import RemoveFormat from '@ckeditor/ckeditor5-remove-format';
import {Table, TableCellProperties, TableProperties, TableToolbar} from '@ckeditor/ckeditor5-table';
import {TextTransformation} from "@ckeditor/ckeditor5-typing";
import {SimpleUploadAdapter} from "@ckeditor/ckeditor5-upload";


// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import {Alignment} from '@ckeditor/ckeditor5-alignment';
// //// import Autoformat from '@ckeditor/ckeditor5-autoformat';
// // import {BlockQuote} from '@ckeditor/ckeditor5-block-quote';
// import {Bold, Italic, Strikethrough, Underline} from '@ckeditor/ckeditor5-basic-styles';
// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
// // import {UploadAdapter as CKFinderUploadAdapter} from "@ckeditor/ckeditor5-adapter-ckfinder";
// //// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder.js';
// import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// // import {FontBackgroundColor, FontColor, FontFamily, FontSize} from '@ckeditor/ckeditor5-font';
// // import {Heading} from '@ckeditor/ckeditor5-heading';
// // import {HorizontalLine} from '@ckeditor/ckeditor5-horizontal-line';
// // import {HtmlEmbed} from "@ckeditor/ckeditor5-html-embed";
// import Image from "@ckeditor/ckeditor5-image/src/image";
// // import {Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload} from '@ckeditor/ckeditor5-image';
// // import {Indent} from '@ckeditor/ckeditor5-indent';
// import Link from '@ckeditor/ckeditor5-link/src/link';
// // import {List, ListProperties} from '@ckeditor/ckeditor5-list';
// // import {Paragraph} from '@ckeditor/ckeditor5-paragraph';
// // import {PasteFromOffice} from '@ckeditor/ckeditor5-paste-from-office';
// //// import RemoveFormat from '@ckeditor/ckeditor5-remove-format';
// // import {Table, TableCellProperties, TableProperties, TableToolbar} from '@ckeditor/ckeditor5-table';
// // import {TextTransformation} from "@ckeditor/ckeditor5-typing";


export default class ClassicEditor extends ClassicEditorBase {
}

ClassicEditor.builtinPlugins = [
  Alignment,
  //// Autoformat,
  BlockQuote,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  HtmlEmbed,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  MediaEmbedToolbar,
  Paragraph,
  PasteFromOffice,
  //// RemoveFormat,
  Strikethrough,
  SimpleUploadAdapter,
  Table,
  TableCellProperties,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Underline
];

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'fontBackgroundColor',
      'fontColor',
      'fontSize',
      'fontFamily',
      '|',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'blockQuote',
      'insertTable',
    ]
  },
  image: {
    styles: [
      'alignLeft', 'alignCenter', 'alignRight'
    ],
    toolbar: [
      'imageStyle:alignCenter',
      '|',
      'imageStyle:alignLeft', 'imageStyle:alignRight',
      //'imageStyle:full',
      'imageStyle:side',
      '|',
      'imageStyle:alignBlockLeft',
      'imageStyle:alignBlockRight',
      '|',
      'imageTextAlternative',
      'toggleImageCaption',
      'linkImage'
    ]
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableCellProperties',
      'tableProperties'
    ]
  },
  link: {
    decorators: {
      openInNewTab: {
        mode: 'manual',
        label: 'Open in a new tab',
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      }
    }
  },
  language: 'fr'
};

// export default ClassicEditor;

// window.SasaEditor = SasaEditor;
